import React from "react";
import { Space } from "../../antd-ovveride";
import ActivityCardConnected from "./ActivityCardConnected";

const ActivitiesList = React.memo(
  ({
    activities,
    selectedActivities,
    addedActivities,
    selectedCodes,
    addedCodes,
    onActivityCardClick,
    onActivityCardBtnClick,
  }) => (
    <Space block direction="vertical" size={15}>
      {activities
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((act) => (
          <ActivityCardConnected
            key={act.id}
            activity={act}
            selectedActivities={selectedActivities}
            addedActivities={addedActivities}
            selectedCodes={selectedCodes[act.id]}
            addedCodes={addedCodes[act.id]}
            onActivityCardClick={onActivityCardClick}
            onActivityCardBtnClick={onActivityCardBtnClick}
          />
        ))}
    </Space>
  )
);

export default ActivitiesList;
