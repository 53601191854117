import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useCurrentUser } from "../hooks/user";

function ProtectedRoute({ roles = [] }) {
  let component = <Outlet />;
  const [userState] = useCurrentUser();

  const blockchainRoles = userState?.data?.user_blockchain_roles?.map(
    (r) => r.blockchain_role
  );

  const rolesIntersected = roles.filter((x) => blockchainRoles?.includes(x));
  if (roles.length && !rolesIntersected.length) {
    component = <Navigate to="/company/taxonomy" />;
  }

  const unauthorized = !!userState.error || !userState?.data?.id;
  if (unauthorized && !userState.fetching) {
    window.location.replace(`${process.env.REACT_APP_AUTH_URL}`);
  }

  return component;
}

export default ProtectedRoute;
