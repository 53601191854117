const getValuesFromUnits = (units) =>
  units.reduce(
    (values, unit) => {
      const result = { ...values };
      Object.keys(values).forEach((key) => {
        result[key] += unit[key];
      });
      return result;
    },
    {
      opex: 0,
      turnover: 0,
      capex: 0,
      opex_alignment: 0,
      turnover_alignment: 0,
      capex_alignment: 0,
    }
  );

export const doubleActivitiesAdapter = (activities) =>
  activities.reduce((result, activityReport) => {
    const activityTypes = [
      ...new Set(activityReport.units.map((unit) => unit.activity_type)),
    ];
    if (activityTypes.length === 2) {
      activityTypes.forEach((actType) => {
        const targetUnits = activityReport.units.filter(
          (unit) => unit.activity_type === actType
        );
        result.push({
          ...activityReport,
          activity_type: actType,
          units: targetUnits,
          ...getValuesFromUnits(targetUnits),
        });
      });
    }
    if (activityTypes.length === 1) {
      result.push({
        ...activityReport,
        activity_type: activityTypes[0],
      });
    }
    return result;
  }, []);

export const objectivesAdapter = (activities) =>
  activities.reduce((result, activityReport) => {
    const objectives = [
      ...new Set(activityReport.units.map((unit) => unit.objective)),
    ];
    const secondaryObjectives = activityReport.units.reduce((acc, unit) => {
      unit.substantialContributionDecisions.forEach((decision) => {
        if (
          unit.objective !== decision.point.objective &&
          acc.indexOf(decision.point.objective) === -1
        ) {
          acc.push(decision.point.objective);
        }
      });
      return acc;
    }, []);
    objectives.forEach((objective) => {
      const targetUnits = activityReport.units.filter(
        (unit) => unit.objective === objective
      );
      result.push({
        ...activityReport,
        objective,
        secondaryObjectives,
        units: targetUnits,
        ...getValuesFromUnits(targetUnits),
      });
    });
    return result;
  }, []);
