import { decimalToPercents } from "../../helpers/percents";
import { thousandFormatter } from "../../helpers/thousandFormatter";

export default {
  percentOrText: (text) =>
    typeof text === "number" ? decimalToPercents(text).slice(0, -1) : text,
  percent: (text) =>
    typeof text === "number" ? decimalToPercents(text).slice(0, -1) : "",
  bool: (text) => (text ? `Y` : "N/A"),
  codes: (text) => (text ? text.join(" / ") : ""),
  currency_mln: (text) => {
    if (!text) return "0";
    const roundedValue = Number((text / 1e6).toFixed(3));
    return thousandFormatter(roundedValue);
  },
  default: (text) => text,
};
