import { gql } from "urql";
import {
  activitiesReportsFieldsExtendedFragment,
  messageFragment,
  threadFragment,
  unitFieldsFragment,
} from "./fragments";

// eslint-disable-next-line import/prefer-default-export
export const getActivitiesReportsSubscription = gql`
  ${activitiesReportsFieldsExtendedFragment}

  subscription getActivitiesReports($reportId: uuid!) {
    eu_taxonomy_activities_reports(
      where: { report_id: { _eq: $reportId } }
      order_by: { created_at: desc }
    ) {
      ...activitiesReportsFieldsExtended
    }
  }
`;

export const getUnitsByReportSubscription = gql`
  ${unitFieldsFragment}
  subscription getUnitsByReport($reportId: uuid!) {
    eu_taxonomy_units(
      where: { activities_report: { report_id: { _eq: $reportId } } }
    ) {
      ...unitFields
    }
  }
`;

export const notificationsSubscription = gql`
  subscription notifications(
    $userId: uuid!
    $lastReadMessageDate: timestamptz!
  ) {
    messenger_threads(
      where: {
        messages: {
          _or: [
            { user_id: { _eq: $userId } }
            { mentions: { user_id: { _eq: $userId } } }
          ]
        }
      }
    ) {
      messages(
        where: {
          user_id: { _neq: $userId }
          created_at: { _gt: $lastReadMessageDate }
        }
      ) {
        text
      }
      thread_state {
        id
        name
      }
    }
  }
`;

export const threadsByStatesSubscription = gql`
  ${threadFragment}
  ${messageFragment}
  subscription threads($config: jsonb!) {
    messenger_thread_states {
      name
      id
      threads(
        order_by: { topic: asc }
        where: { config: { _contains: $config } }
      ) {
        ...thread
        creator {
          id
          email
          avatar_url
          display_name
        }
        messages(order_by: { created_at: asc }) {
          ...message
          author {
            avatar_url
            display_name
            id
          }
          mentions {
            user_id
            message_id
          }
        }
      }
    }
  }
`;

export const threadsSubscription = gql`
  ${threadFragment}
  subscription threads($config: jsonb!) {
    messenger_threads(where: { config: { _contains: $config } }) {
      ...thread
    }
  }
`;

export const messagesSubscription = gql`
  ${messageFragment}
  subscription messages($threadId: uuid!) {
    messenger_messages(where: { thread_id: { _eq: $threadId } }) {
      ...message
      author {
        avatar_url
        display_name
        id
      }
    }
  }
`;
