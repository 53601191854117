import cn from "classnames";
import React from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";

import Socials from "./Socials";

import styles from "./PublicFooter.module.less";
import PublicLayoutContainer from "../PublicLayoutContainer/PublicLayoutContainer";
import { useCurrentUser } from "../../hooks/user";

const PublicFooter = () => {
  const [userState] = useCurrentUser();

  const isUser = !!userState.data.id;
  return (
    <div className={styles.footer}>
      <PublicLayoutContainer>
        <div className={styles.grid}>
          <div>
            <dl className={styles.nav}>
              <dd className={styles.navItem}>
                <a
                  href="http://evercity.io/"
                  className={cn(styles.link)}
                  target="_blank"
                  rel="noreferrer"
                >
                  About
                </a>
              </dd>

              <dd className={styles.navItem}>
                <Link to="/" className={cn(styles.link)}>
                  Home
                </Link>
              </dd>
            </dl>
          </div>

          <div>
            <dl className={styles.nav}>
              <dd className={styles.navItem}>
                <a
                  href="mailto:info@evercity.io"
                  className={cn(styles.link, styles.undecorated)}
                >
                  info@evercity.io
                </a>
              </dd>
              {!isUser && (
                <dd className={styles.navItem}>
                  <Link to="/request-demo" className={cn(styles.link)}>
                    Request a Demo
                  </Link>
                </dd>
              )}
            </dl>
          </div>

          <div className={styles.socialsItem}>
            <Typography.Title level={5}>Join our community!</Typography.Title>
            <Socials className={styles.socials} />
          </div>
        </div>

        <p className={styles.copyright}>
          © Evercity UG (haftungsbeschränkt), 2021.
          <br />
          All rights reserved.
        </p>
      </PublicLayoutContainer>
    </div>
  );
};

export default PublicFooter;
