import React from "react";
import { Select, Text } from "@antd-ovveride";
import { useMutation } from "urql";
import styles from "./ObjectiveCell.module.less";

import { RECORD_TYPE } from "../../ActivitiesTable/types";

import { updateUnitMutation } from "../../../api/mutations";

const ObjectiveCell = ({ record }) => {
  const [, updateUnit] = useMutation(updateUnitMutation);

  const onSelect = async (objective) => {
    await updateUnit({
      unitId: record.element.id,
      changes: {
        objective,
      },
    });
  };
  if (RECORD_TYPE.ACTIVITY === record.keyType || !record.objective) {
    return <></>;
  }

  const availableObjectives = record?.availableObjectives || [];

  if (
    availableObjectives.length < 2 ||
    record.keyType === RECORD_TYPE.SUBUNIT
  ) {
    return (
      <Text size="small" capitalize>
        {record.objective}
      </Text>
    );
  }

  return (
    <Select
      capitalize
      dropdownClassName={styles.dropdownObjectives}
      dropdownMatchSelectWidth={false}
      className={styles.selectObjective}
      size="small"
      value={record?.objective}
      onSelect={onSelect}
      bordered={false}
      onClick={(e) => {
        e.stopPropagation();
      }}
      options={availableObjectives.map((objective) => ({
        label: objective,
        value: objective,
      }))}
    />
  );
};

export default ObjectiveCell;
