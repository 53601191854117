import { useMemo } from "react";
import { useStore } from "effector-react";
import Session from "supertokens-web-js/recipe/session";
import { $currentUser } from "../../entities/User/model";
import { LS_TOKEN_KEY } from "../../helpers/jwt";

const AUTH_URL = process.env.REACT_APP_AUTH_URL;

// eslint-disable-next-line import/prefer-default-export
export const useCurrentUser = () => {
  const { loadingUser, loadingSession, user } = useStore($currentUser);

  const company = useMemo(() => {
    const counterparties = {};
    if (user?.company?.type === "consultant") {
      counterparties.clients =
        user?.company?.companyConsultantsByConsultantId?.map(
          (counterparty) => counterparty.company
        );
    }
    if (user?.company?.type === "client") {
      counterparties.consultants = user?.company?.company_consultants?.map(
        (counterparty) => counterparty.companyByConsultantId
      );
    }
    return {
      name: user?.company?.name,
      type: user?.company?.type,
      id: user?.company?.id,
      settings: user?.company?.settings,
      ...counterparties,
    };
  }, [user]);

  const userState = useMemo(
    () => ({
      fetching: loadingUser || loadingSession,
      error: null,
      company,
      userRole: "",
      data: user,
    }),
    [user, company, loadingSession, loadingUser]
  );

  const logout = async () => {
    await Session.signOut();
    localStorage.removeItem(LS_TOKEN_KEY);
    window.location.replace(AUTH_URL);
    $currentUser.reset();
  };

  return [userState, { logout }];
};
