import React from "react";
import {
  Section,
  TextArea,
  Select,
  MultipleSelect,
  Form,
  Title,
  TreeSelect,
} from "@evercityecosystem/evercity-ui";
import { Tooltip } from "antd";
import { useOutletContext } from "react-router-dom";
import {
  flatProjectTypes,
  methodologies,
  projectTypes,
  scaleOptions,
  sectoralScopesTree,
  UNFCCCSignatories,
} from "../../data/PIN";
import adapterOptions from "../../helpers/adapterOptions";
import Goal from "../GoalsList/Goal";
import InfoButton from "../InfoButton/InfoButton";
import MethodologiesNote from "../ProjectIdeaNoteFlow/MethodologiesNote";
import QuestionWithThreads, {
  GroupContext,
} from "../QuestionWithThreads/QuestionWithThreads";

const ProjectIdeaNoteSummary = ({ goals, threads }) => {
  const { withoutThreshold } = useOutletContext();

  return (
    <GroupContext.Provider value="summary">
      <Title level={3} size="middle">
        1. Project Summary
      </Title>
      <Section number="1.1" title="Overview">
        <QuestionWithThreads
          threads={threads}
          optional={withoutThreshold}
          property="summary_project_owner"
          topic="1.1.1 Project Owner"
          number="1.1.1"
          title={
            <Tooltip
              title="Owner is the entity that owns the asset where the emission reduction/removal is happening (e.g. forest land, factory, etc.)."
              placement="rightBottom"
            >
              Project Owner <InfoButton />
            </Tooltip>
          }
          description="Please indicate the name of the entity that owns the project."
        >
          <Form.DiscussedItem
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            name="summary_project_owner"
            trigger="onBlur"
            validateTrigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          property="summary_project_title"
          topic="1.1.2 Project title"
          number="1.1.2"
          title="Project title"
          description="What is the title of the project? You are recommended to include the place and the technology type in the title."
        >
          <Form.DiscussedItem
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            name="summary_project_title"
            trigger="onBlur"
            validateTrigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" wordLimit={10} showCount roundCorners />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          property="summary_country"
          number="1.1.3"
          title="Country"
          description="In which country will the project be implemented?"
        >
          <Form.DiscussedItem
            name="summary_country"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <Select
              roundCorners
              size="large"
              showSearch
              options={UNFCCCSignatories.map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          optional={withoutThreshold}
          property="summary_brief_description"
          number="1.1.4"
          title="Brief project description"
          description="Briefly describe your project. Please note there is a Detailed project description field on the following tab."
        >
          <Form.DiscussedItem
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            name="summary_brief_description"
            trigger="onBlur"
            validateTrigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" wordLimit={500} showCount roundCorners />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          property="summary_project_type"
          number="1.1.5"
          title="Sectoral scopes and project type"
          description="Please indicate the sectoral scope(s) applicable to the Project."
        >
          <Form.DiscussedItem name="summary_scope" hidden />
          <Form.DiscussedItem shouldUpdate>
            {({ setFieldsValue }) => (
              <Form.DiscussedItem
                rules={[
                  { required: !withoutThreshold, message: "Field is required" },
                ]}
                name="summary_project_type"
              >
                <TreeSelect
                  tree={projectTypes}
                  size="large"
                  onChange={(value, label, extra) => {
                    const position = extra.allCheckedNodes[0].pos;
                    const scopeIndex = position.match(/^\d+-(\d+)/)[1];
                    const scope = Object.keys(sectoralScopesTree)[scopeIndex];
                    setFieldsValue({
                      summary_standard: null,
                      summary_scope: scope,
                    });
                  }}
                />
              </Form.DiscussedItem>
            )}
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          property="summary_technology"
          number="1.1.6"
          title="Technology to be employed"
          description="Please specify and explain the type of technology used in the Project. This may include: transfer of technology, history of this technology in the region/country/ area, experience in operating this technology, etc."
        >
          <Form.DiscussedItem
            validateTrigger="onBlur"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            name="summary_technology"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" wordLimit={500} showCount roundCorners />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          optional={withoutThreshold}
          property="summary_standard"
          number="1.1.7"
          title="Standard"
          description="What carbon standard are you planning to use?"
        >
          <Form.DiscussedItem shouldUpdate>
            {({ getFieldValue }) => (
              <Form.DiscussedItem
                rules={[
                  { required: !withoutThreshold, message: "Field is required" },
                ]}
                name="summary_standard"
              >
                <Select
                  roundCorners
                  disabled={!getFieldValue("summary_project_type")}
                  options={[
                    ...(flatProjectTypes[
                      getFieldValue("summary_project_type")
                    ]?.map((item) => ({
                      label: item,
                      value: item,
                    })) || []),
                    {
                      label: "Other",
                      value: "Other",
                    },
                  ]}
                  size="large"
                />
              </Form.DiscussedItem>
            )}
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          property="summary_methodology"
          number="1.1.8"
          title="Methodology used"
          description="What methodology are you using?"
        >
          <MethodologiesNote title="You may consult the list of methodologies below:" />
          <Form.DiscussedItem
            validateTrigger="onBlur"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            name="summary_methodology"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" wordLimit={150} showCount roundCorners />
          </Form.DiscussedItem>
          <QuestionWithThreads
            optional={withoutThreshold}
            description="Is there an existing methodology for the Project?"
          >
            <Form.DiscussedItem
              name="summary_existing_methodology"
              rules={[
                { required: !withoutThreshold, message: "Field is required" },
              ]}
            >
              <Select
                roundCorners
                options={methodologies.map((item) => ({
                  label: item,
                  value: item,
                }))}
                size="large"
              />
            </Form.DiscussedItem>
          </QuestionWithThreads>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          property="summary_scale"
          number="1.1.9"
          title="Scale"
          description="Specify project size depending estimate of GHG abated (in metric tons of CO2-equivalent anually during a period of t years)"
        >
          <Form.DiscussedItem
            name="summary_scale"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <Select
              roundCorners
              options={scaleOptions.map((item) => ({
                label: item,
                value: item,
              }))}
              size="large"
            />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          property="summary_goals"
          number="1.1.10"
          title="Sustainable Development Goals"
        >
          <Form.DiscussedItem
            name="summary_goals"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <MultipleSelect
              size="large"
              roundCorners
              options={adapterOptions(goals, true).map((option) => ({
                label: <Goal id={option.value} name={option.label} />,
                value: option.value,
              }))}
              showSearch={false}
            />
          </Form.DiscussedItem>
        </QuestionWithThreads>
      </Section>
    </GroupContext.Provider>
  );
};

export default ProjectIdeaNoteSummary;
