import React from "react";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  AimOutlined,
} from "@ant-design/icons";
import ActivityTypeCell from "./Cells/ActivityTypeCell";
import CodeCell from "./Cells/CodeCell";
import ObjectiveCell from "./Cells/ObjectiveCell";
import StatusCell from "./Cells/StatusCell";
import ActionsCell from "./Cells/ActionsCell";
import ActivityNameCell from "./Cells/ActivityNameCell";
import UnitNameCell from "./Cells/UnitNameCell";
import FinParamCell from "./Cells/FinParamCell";
import { RECORD_TYPE } from "../ActivitiesTable/types";
import ExpandedCellWrapper from "../ActivitiesTable/ExpandedCellWrapper";

export const renderNameCell =
  (expandedRows, expandChange) =>
  (value, { key, keyType, children: recordChildren }) => {
    const onExpandIconClick = (e) => {
      e.stopPropagation();
      if (expandedRows.includes(key)) {
        expandChange(expandedRows.filter((r) => r !== key));
      } else {
        expandChange([...expandedRows, key]);
      }
    };
    let component;
    switch (keyType) {
      case RECORD_TYPE.ACTIVITY:
        component = (
          <ActivityNameCell value={value} expandedRows={expandedRows} />
        );
        break;
      default:
        component = <UnitNameCell value={value} />;
    }

    return (
      <ExpandedCellWrapper
        expandedRows={expandedRows}
        onExpandIconClick={onExpandIconClick}
        expanded={expandedRows.includes(key)}
        hidden={!recordChildren?.length}
      >
        {component}
      </ExpandedCellWrapper>
    );
  };

export const renderCodeCell = (codes) =>
  codes?.length ? (
    <div>
      <CodeCell codes={codes} />
    </div>
  ) : null;

export const renderAcitivtyTypeCell = (text, record, index) => (
  <ActivityTypeCell text={text} record={record} index={index} />
);

export const renderObjectiveCell = (text, record, index) => (
  <ObjectiveCell text={text} record={record} index={index} />
);

export const renderFinParamCell = (value, record) => (
  <FinParamCell value={value} record={record} />
);

export const renderStatusCell = (text, record, index) => (
  <StatusCell text={text} record={record} index={index} />
);

export const renderActionsCell =
  ({
    onDeleteActivity,
    onDeleteUnit,
    onAddUnit,
    onAddSubunit,
    onChangeObjective,
    onEditAcitivityNaceCodes,
  }) =>
  (value, record) => {
    const activityActions = ({ element: { id } }) => [
      {
        type: "add_unit",
        label: "Add unit",
        onClick: () => onAddUnit(id),
        icon: <PlusCircleOutlined />,
      },
      {
        type: "edit_nace_code",
        label: "Edit NACE code",
        onClick: () => onEditAcitivityNaceCodes(id),
        icon: <EditOutlined />,
      },
      {
        type: "delete",
        label: "Delete",
        onClick: () => onDeleteActivity(id),
        icon: <DeleteOutlined />,
      },
    ];
    const unitActions = ({ availableObjectives, element: { id } }) => [
      {
        type: "add_subunit",
        label: "Add subunit",
        onClick: () => onAddSubunit(id),
        icon: <PlusCircleOutlined />,
      },
      {
        type: "change_objective",
        label: "Change objective",
        disabled: availableObjectives.length < 2,
        onClick: () => onChangeObjective(id),
        icon: <AimOutlined />,
      },
      {
        type: "delete",
        label: "Delete",
        onClick: () => onDeleteUnit(id),
        icon: <DeleteOutlined />,
      },
    ];
    const subunitActions = ({ element: { id } }) => [
      {
        type: "delete",
        label: "Delete",
        onClick: () => onDeleteUnit(id),
        icon: <DeleteOutlined />,
      },
    ];

    let actions;
    switch (record.keyType) {
      case RECORD_TYPE.ACTIVITY:
        actions = activityActions(record);
        break;
      case RECORD_TYPE.UNIT:
        actions = unitActions(record);
        break;
      case RECORD_TYPE.SUBUNIT:
      default:
        actions = subunitActions(record);
        break;
    }

    return <ActionsCell actions={actions} />;
  };
