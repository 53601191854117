import React from "react";
import { Modal, Typography } from "antd";
import ReactPlayer from "react-player";
import cs from "classnames";
import styles from "./EntryModal.module.less";
import { Text } from "../../antd-ovveride";
import { ReactComponent as PlayIcon } from "../../assets/icons/youtube-play.svg";

const { Title } = Typography;

const EntryModal = ({
  startTime = 0,
  visible,
  onHide,
  closable = false,
  autoplay = false,
}) => (
  <Modal
    visible={visible}
    wrapClassName={cs(
      styles.container,
      closable && styles["container--noFooter"]
    )}
    destroyOnClose
    footer={
      !closable && (
        <Text
          data-cy="entry-skip"
          size="large"
          onClick={() => onHide()}
          className={styles.closeBtn}
        >
          No, thanks
        </Text>
      )
    }
    onCancel={() => closable && onHide()}
    closable={closable}
  >
    <Title level={2} style={{ marginBottom: 0 }}>
      Welcome to Evercity!
    </Title>
    <Text size="large" className={styles.description}>
      Let&rsquo;s watch a&nbsp;video tutorial to&nbsp;get you started.
    </Text>
    <div className={styles.player}>
      <ReactPlayer
        data-cy="iframe-entry-video"
        url="https://www.youtube.com/watch?v=4sEZ_J2jJ5Y"
        light={!autoplay}
        playIcon={<PlayIcon />}
        config={{
          youtube: {
            playerVars: {
              modestbranding: 1,
              autoplay: 1,
              controls: 2,
              rel: 0,
              showinfo: 0,
              start: startTime,
            },
          },
        }}
      />
    </div>
  </Modal>
);

export default EntryModal;
