import dayjs from "dayjs";
import Session from "supertokens-web-js/recipe/session";
import { decodeJwt } from "jose";
import { PDF_MODE } from "../shared/lib/constants";

export const LS_TOKEN_KEY = "accessToken";

export const setData = (token) => {
  const oldValue = window.localStorage.getItem(LS_TOKEN_KEY);
  if (!token) {
    localStorage.removeItem(LS_TOKEN_KEY);
  }

  if (token) {
    localStorage.setItem(LS_TOKEN_KEY, token);
  }

  const event = new StorageEvent("storage", {
    key: LS_TOKEN_KEY,
    oldValue,
    newValue: token,
  });

  window.dispatchEvent(event);
};

export const getDataFromSecret = async () => {
  let token = localStorage.getItem(LS_TOKEN_KEY);
  const staticCurrentUserId = process.env.REACT_APP_STATIC_CURRENT_USER_ID;
  const authGatewayURL = process.env.REACT_APP_AUTH_API_URL;
  const adminSecret = process.env.REACT_APP_HASURA_ADMIN_SECRET;

  if (!staticCurrentUserId) {
    console.warn("Static user id is not set. Continuing unauthorized mode");
    return {};
  }

  let payload = {};

  if (token) {
    payload = decodeJwt(token);
    const userId = payload.hasura["x-hasura-user-id"];
    const expiration = payload.exp * 1000;

    if (
      (process.env.REACT_APP_BACKEND_ENV !== "local" &&
        userId !== staticCurrentUserId) ||
      dayjs().isAfter(dayjs(expiration))
    ) {
      payload = {};
      token = null;
      localStorage.removeItem(LS_TOKEN_KEY);
    }
  }

  if (!token) {
    const tokenResponse = await fetch(
      `${authGatewayURL}/jwt/static?service=carbon&user_id=${staticCurrentUserId}&secret=${adminSecret}`
    ).then((res) => res.json());
    token = tokenResponse.accessToken;

    if (!token) {
      console.error("Invalid jwt");
      return {};
    }

    try {
      payload = decodeJwt(token);
      localStorage.setItem(LS_TOKEN_KEY, token);
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  const userRole = payload.hasura["x-hasura-default-role"];

  return {
    token,
    userId: process.env.REACT_APP_STATIC_CURRENT_USER_ID,
    userRole,
  };
};

export const getDataFromSession = async () => {
  let token = localStorage.getItem(LS_TOKEN_KEY);

  if (PDF_MODE) {
    console.log("PDF_MODE from session: ", PDF_MODE);
    console.log("token", token);
    if (!token) {
      return {};
    }

    let result = {};
    let userId = null;
    let userRole = null;

    try {
      result = decodeJwt(token);
      userId = result.hasura["x-hasura-user-id"];
      userRole = result.hasura["x-hasura-default-role"];
    } catch (e) {
      console.error(e);
      return {};
    }

    return {
      token,
      userId,
      userRole,
    };
  }

  if (
    !(await Session.doesSessionExist()) &&
    process.env.NODE_ENV !== "development"
  ) {
    localStorage.removeItem(LS_TOKEN_KEY);
    token = null;
    return {};
  }

  if (!token && process.env.NODE_ENV === "development") {
    return {};
  }

  let result = {};
  let userId = null;

  if (token) {
    try {
      result = decodeJwt(token);
    } catch (e) {
      console.error(e);
      return {};
    }

    userId = result.hasura["x-hasura-user-id"];
    const sessionUserId = await Session.getUserId();
    const expiration = result.exp * 1000;

    if (
      process.env.NODE_ENV !== "development" &&
      (userId !== sessionUserId || dayjs().isAfter(dayjs(expiration)))
    ) {
      localStorage.removeItem(LS_TOKEN_KEY);
      token = null;
      userId = null;
      result = {};
    }
  }

  if (!token) {
    const tokenResponse = await fetch(
      `${process.env.REACT_APP_AUTH_API_URL}/jwt/static?service=legacy`
    ).then((res) => res.json());
    token = tokenResponse.accessToken;

    if (!token) {
      console.error("Invalid jwt");
      return {};
    }

    localStorage.setItem(LS_TOKEN_KEY, token);

    try {
      result = decodeJwt(token);
      userId = result.hasura["x-hasura-user-id"];
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  const userRole = result.hasura["x-hasura-default-role"];

  return {
    token,
    userId,
    userRole,
  };
};
