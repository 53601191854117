import React, { useState } from "react";
import {
  Button,
  PlusCircleOutlined,
  Modal,
} from "@evercityecosystem/evercity-ui";
import { useMutation } from "urql";
import { useNavigate } from "react-router-dom";
import { createPINMutation } from "../../api/mutations";
import { useCurrentUser } from "../../hooks/user";
import styles from "./AddNewPIN.module.less";

export const AddNewPIN = () => {
  const navigate = useNavigate();
  const [{ company }] = useCurrentUser();
  const [, createPIN] = useMutation(createPINMutation);
  const [visibleModal, setVisibleModal] = useState(false);
  const onAdd = () => {
    setVisibleModal(true);
  };

  const onCreatePIN = (visibility) => {
    createPIN({
      companyId: company.id,
      visibility,
    }).then(({ data }) => {
      if (data?.insert_commodity_PINs_one?.id) {
        navigate(
          `/company/pins/${data?.insert_commodity_PINs_one?.id}/summary`
        );
      }
    });
  };

  return (
    <>
      <Button type="primary" onClick={onAdd}>
        <PlusCircleOutlined /> New PIN
      </Button>
      <Modal
        wrapClassName={styles.modal}
        onCancel={() => setVisibleModal(false)}
        visible={visibleModal}
        title="Would you like your PIN to be visible with your verifier? This can not be changed later."
        footer={[
          <Button onClick={() => onCreatePIN(true)}>Yes</Button>,
          <Button onClick={() => onCreatePIN(false)}>No</Button>,
        ]}
      />
    </>
  );
};
