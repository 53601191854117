import React from "react";
import { Avatar, DeleteOutlined, Table } from "@evercityecosystem/evercity-ui";
import styles from "./TeamTable.module.less";

const TeamTable = ({ onRemove, users, currentUser }) => (
  <Table dataSource={users}>
    <Table.Column
      title="Name"
      key="display_name"
      dataIndex="display_name"
      render={(name) => (
        <>
          <Avatar name={name} /> {name}
        </>
      )}
    />
    <Table.Column
      title="Email"
      key="email"
      dataIndex="account"
      render={(account) => account.email}
    />
    <Table.Column
      title="Role"
      key="company_role"
      className={styles["column-role"]}
      dataIndex="company_role"
    />
    <Table.Column
      title="Actions"
      dataIndex="id"
      render={(id) => (
        <Table.Actions
          title="Actions"
          key="actions"
          dataIndex="id"
          actions={[
            {
              key: "delete",
              icon: <DeleteOutlined />,
              label: "Delete",
              danger: true,
              disabled:
                currentUser.id === id || currentUser.company_role !== "owner",
              onClick: () => onRemove(id),
            },
          ]}
        />
      )}
    />
  </Table>
);
export default TeamTable;
