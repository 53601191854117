import React from "react";
import { Modal, Button, Typography } from "antd";
import { Text } from "@antd-ovveride";
import {
  ExclamationCircleOutlined,
  RedoOutlined,
  SaveOutlined,
} from "@evercityecosystem/evercity-ui";
import styles from "./ObjectiveModal.module.less";

const { Title } = Typography;

const AnotherOneObjectiveModal = ({ onOk, onRetry, visible }) => (
  <Modal
    width={600}
    visible={visible}
    title={
      <div className={styles.headerContent}>
        <ExclamationCircleOutlined />
        <Title level={2} style={{ marginBottom: 0 }}>
          Environmental objectives
        </Title>
      </div>
    }
    wrapClassName={styles.container}
    closable={false}
    footer={[
      <Button
        key="retry"
        onClick={onRetry}
        size="large"
        icon={<RedoOutlined />}
      >
        Retry
      </Button>,
      <Button
        key="save_and_proceed"
        type="primary"
        onClick={onOk}
        size="large"
        icon={<SaveOutlined />}
      >
        Save and proceed
      </Button>,
    ]}
  >
    <Text className={styles.message} size="large">
      Your economic activity potentially can contribute to multiple
      environmental objectives. Do you want to screen this unit according to
      another one?
    </Text>
  </Modal>
);

export default AnotherOneObjectiveModal;
