import { Objectives } from "./model";

export const getAvailableObjectives = (activity) => {
  const availableObjectives = [];
  Object.values(Objectives)
    .sort((a, b) => a.priority - b.priority)
    .forEach((objective) => {
      if (activity[`${objective.value}_type`]) {
        availableObjectives.push(objective.value);
      }
    });

  return availableObjectives;
};

export const getActivityType = (objective, activity) =>
  activity[`${objective}_type`];
