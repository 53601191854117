import React from "react";
import { Table } from "antd";
import styles from "./ReportPdfTable.module.less";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import {
  doubleActivitiesAdapter,
  objectivesAdapter,
} from "../../entities/Activity/lib";
import { decimalToPercents } from "../../helpers/percents";
import HeaderCell from "./HeaderCell";

const ReportPdfObjectives = ({ actReports, report }) => {
  const getAlignedPerObjective = ({ finParam, objective }) => {
    const alignedActivities = doubleActivitiesAdapter(
      objectivesAdapter(actReports)
    ).filter((ar) => ar[`${finParam}_alignment`] > 0);
    const totalValuePerObjective = alignedActivities.reduce(
      (result, actReport) => {
        if (actReport.objective === objective) {
          return result + actReport[`${finParam}_alignment`];
        }
        return result;
      },
      0
    );
    return totalValuePerObjective / report[finParam];
  };

  const getEligiblePerObjective = ({ finParam, objective }) => {
    const eligibleActReports = actReports.filter((ar) => ar[finParam] > 0);
    const notAlignedActivities = doubleActivitiesAdapter(
      objectivesAdapter(eligibleActReports)
    ).filter(
      (actReport) => actReport[`${finParam}_alignment`] < actReport[finParam]
    );
    const totalValuePerObjective = notAlignedActivities.reduce(
      (result, actReport) => {
        if (actReport.objective === objective) {
          return result + actReport[finParam];
        }
        return result;
      },
      0
    );
    return totalValuePerObjective / report[finParam];
  };

  const renderPercent = (text) => decimalToPercents(text).slice(0, -1);

  const columns = [
    {
      title: "",
      children: [
        {
          title: () => <HeaderCell horizontal>Code</HeaderCell>,
          dataIndex: "code",
        },
      ],
    },
    {
      title: () => (
        <HeaderCell horizontal>
          Proportion of turnover/Total turnover
        </HeaderCell>
      ),
      children: [
        {
          title: () => (
            <HeaderCell measure="%">Taxonomy-aligned per objective</HeaderCell>
          ),
          dataIndex: "proportionAlignedTurnover",
          key: "proportionAlignedTurnover",
          render: renderPercent,
        },
        {
          title: () => (
            <HeaderCell measure="%">Taxonomy-eligible per objective</HeaderCell>
          ),
          dataIndex: "proportionEligibleTurnover",
          key: "proportionEligibleTurnover",
          render: renderPercent,
        },
      ],
    },
    {
      title: () => (
        <HeaderCell horizontal>Proportion of CapEx/Total CapEx</HeaderCell>
      ),
      children: [
        {
          title: () => (
            <HeaderCell measure="%">Taxonomy-aligned per objective</HeaderCell>
          ),
          dataIndex: "proportionAlignedCapex",
          key: "proportionAlignedCapex",
          render: renderPercent,
        },
        {
          title: () => (
            <HeaderCell measure="%">Taxonomy-eligible per objective</HeaderCell>
          ),
          dataIndex: "proportionEligibleCapex",
          render: renderPercent,
        },
      ],
    },
    {
      title: () => (
        <HeaderCell horizontal>Proportion of OpEx/Total OpEx</HeaderCell>
      ),
      children: [
        {
          title: () => (
            <HeaderCell measure="%">Taxonomy-aligned per objective</HeaderCell>
          ),
          dataIndex: "proportionAlignedOpex",
          key: "proportionAlignedOpex",
          render: renderPercent,
        },
        {
          title: () => (
            <HeaderCell measure="%">Taxonomy-eligible per objective</HeaderCell>
          ),
          dataIndex: "proportionEligibleOpex",
          key: "proportionEligibleOpex",
          render: renderPercent,
        },
      ],
    },
  ];
  const tableRows = [
    {
      code: "CMM",
      proportionAlignedTurnover: getAlignedPerObjective({
        finParam: "turnover",
        objective: "mitigation",
      }),
      proportionEligibleTurnover: getEligiblePerObjective({
        finParam: "turnover",
        objective: "mitigation",
      }),
      proportionAlignedCapex: getAlignedPerObjective({
        finParam: "capex",
        objective: "mitigation",
      }),
      proportionEligibleCapex: getEligiblePerObjective({
        finParam: "capex",
        objective: "mitigation",
      }),
      proportionAlignedOpex: getAlignedPerObjective({
        finParam: "opex",
        objective: "mitigation",
      }),
      proportionEligibleOpex: getEligiblePerObjective({
        finParam: "opex",
        objective: "mitigation",
      }),
    },
    {
      code: "CCA",
      proportionAlignedTurnover: getAlignedPerObjective({
        finParam: "turnover",
        objective: "adaptation",
      }),
      proportionEligibleTurnover: getEligiblePerObjective({
        finParam: "turnover",
        objective: "adaptation",
      }),
      proportionAlignedCapex: getAlignedPerObjective({
        finParam: "capex",
        objective: "adaptation",
      }),
      proportionEligibleCapex: getEligiblePerObjective({
        finParam: "capex",
        objective: "adaptation",
      }),
      proportionAlignedOpex: getAlignedPerObjective({
        finParam: "opex",
        objective: "adaptation",
      }),
      proportionEligibleOpex: getEligiblePerObjective({
        finParam: "capex",
        objective: "adaptation",
      }),
    },
    {
      code: "WTR",
      proportionAlignedTurnover: getAlignedPerObjective({
        finParam: "turnover",
        objective: "water",
      }),
      proportionEligibleTurnover: getEligiblePerObjective({
        finParam: "turnover",
        objective: "water",
      }),
      proportionAlignedCapex: getAlignedPerObjective({
        finParam: "capex",
        objective: "water",
      }),
      proportionEligibleCapex: getEligiblePerObjective({
        finParam: "capex",
        objective: "water",
      }),
      proportionAlignedOpex: getAlignedPerObjective({
        finParam: "opex",
        objective: "water",
      }),
      proportionEligibleOpex: getEligiblePerObjective({
        finParam: "opex",
        objective: "water",
      }),
    },
    {
      code: "CE",
      proportionAlignedTurnover: getAlignedPerObjective({
        finParam: "turnover",
        objective: "circularEconomy",
      }),
      proportionEligibleTurnover: getEligiblePerObjective({
        finParam: "turnover",
        objective: "circularEconomy",
      }),
      proportionAlignedCapex: getAlignedPerObjective({
        finParam: "capex",
        objective: "circularEconomy",
      }),
      proportionEligibleCapex: getEligiblePerObjective({
        finParam: "capex",
        objective: "circularEconomy",
      }),
      proportionAlignedOpex: getAlignedPerObjective({
        finParam: "opex",
        objective: "circularEconomy",
      }),
      proportionEligibleOpex: getEligiblePerObjective({
        finParam: "opex",
        objective: "circularEconomy",
      }),
    },
    {
      code: "PPC",
      proportionAlignedTurnover: getAlignedPerObjective({
        finParam: "turnover",
        objective: "pollution",
      }),
      proportionEligibleTurnover: getEligiblePerObjective({
        finParam: "turnover",
        objective: "pollution",
      }),
      proportionAlignedCapex: getAlignedPerObjective({
        finParam: "capex",
        objective: "pollution",
      }),
      proportionEligibleCapex: getEligiblePerObjective({
        finParam: "capex",
        objective: "pollution",
      }),
      proportionAlignedOpex: getAlignedPerObjective({
        finParam: "opex",
        objective: "pollution",
      }),
      proportionEligibleOpex: getEligiblePerObjective({
        finParam: "opex",
        objective: "pollution",
      }),
    },
    {
      code: "BIO",
      proportionAlignedTurnover: getAlignedPerObjective({
        finParam: "turnover",
        objective: "biodiversity",
      }),
      proportionEligibleTurnover: getEligiblePerObjective({
        finParam: "turnover",
        objective: "biodiversity",
      }),
      proportionAlignedCapex: getAlignedPerObjective({
        finParam: "capex",
        objective: "biodiversity",
      }),
      proportionEligibleCapex: getEligiblePerObjective({
        finParam: "capex",
        objective: "biodiversity",
      }),
      proportionAlignedOpex: getAlignedPerObjective({
        finParam: "opex",
        objective: "biodiversity",
      }),
      proportionEligibleOpex: getEligiblePerObjective({
        finParam: "opex",
        objective: "biodiversity",
      }),
    },
  ];

  return (
    <div className={styles.page}>
      <p className={styles.pagination}>4/4</p>
      <div className={styles.pageTitleWrapper}>
        <h1 className={styles.pageTitle}>Taxonomy Report</h1>
        <div className={styles.logo}>
          <LogoIcon />
        </div>
      </div>

      <p className={styles.description} />
      <div className={styles.twoColsList}>
        <div className={styles.companyInfoKey}>Company</div>
        <div className={styles.companyInfoValue}>{report.company.name}</div>
        <div className={styles.companyInfoKey}>Year (N)</div>
        <div className={styles.companyInfoValue}>{report.reporting_year}</div>
      </div>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={tableRows}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default ReportPdfObjectives;
