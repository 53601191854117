export const OBJECTIVES = {
  Water: "water",
  Mitigation: "mitigation",
  Adaptation: "adaptation",
  Pollution: "pollution",
  CircularEconomy: "circularEconomy",
  Biodiversity: "biodiversity",
};

export const SCREENING_STATUSES = {
  Aligned: "aligned",
  NotAligned: "not_aligned",
  NotEligible: "not_eligible",
};

const getValidateStatus = (units, objective, finParam) => {
  const results = units.map((unit) => {
    if (unit.objectives_meta[objective]) {
      return {
        value: unit.objectives_meta[objective][`${finParam}_alignment`],
        progress: unit.objectives_meta[objective].screening_progress,
      };
    }
    return {
      value: 0,
      progress: 0,
    };
  });
  if (results.every((result) => result.progress === 100 && result.value > 0)) {
    return SCREENING_STATUSES.Aligned;
  }

  if (results.every((result) => result.progress === 0)) {
    return SCREENING_STATUSES.NotEligible;
  }

  return SCREENING_STATUSES.NotAligned;
};

const validateUnitsByObjectivesAdapter = (activities, finParam) =>
  activities.map((activity) => ({
    ...activity,
    validationStatuses: {
      [OBJECTIVES.Water]: getValidateStatus(
        activity.units,
        OBJECTIVES.Water,
        finParam
      ),
      [OBJECTIVES.Mitigation]: getValidateStatus(
        activity.units,
        OBJECTIVES.Mitigation,
        finParam
      ),
      [OBJECTIVES.Adaptation]: getValidateStatus(
        activity.units,
        OBJECTIVES.Adaptation,
        finParam
      ),
      [OBJECTIVES.Pollution]: getValidateStatus(
        activity.units,
        OBJECTIVES.Pollution,
        finParam
      ),
      [OBJECTIVES.CircularEconomy]: getValidateStatus(
        activity.units,
        OBJECTIVES.CircularEconomy,
        finParam
      ),
      [OBJECTIVES.Biodiversity]: getValidateStatus(
        activity.units,
        OBJECTIVES.Biodiversity,
        finParam
      ),
    },
  }));

export default validateUnitsByObjectivesAdapter;
