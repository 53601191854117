import React from "react";
import { useMutation, useQuery } from "urql";
import { Modal } from "@evercityecosystem/evercity-ui";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ProjectIdeaNotesTable from "./ProjectIdeaNotesTable";
import { useCurrentUser } from "../../hooks/user";
import { deletePINMutation, generatePINMutation } from "../../api/mutations";
import { pinsQuery } from "../../api/queries";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import validationSchema from "../ProjectIdeaNoteFlow/validationSchema";

import calcProgressBySchema from "../../helpers/calcProgressBySchema";

const ProjectIdeaNotesTableConnected = () => {
  const [{ company }] = useCurrentUser();
  const [{ fetching: deleteLoading }, deletePin] =
    useMutation(deletePINMutation);
  const [, generatePin] = useMutation(generatePINMutation);

  const [{ data, fetching }] = useQuery({
    query: pinsQuery,
    variables: {
      companyId: company?.id,
    },
    pause: !company,
  });

  const pins = data?.commodity_PINs
    ?.map((pin) => ({
      ...pin,
      progress: calcProgressBySchema(pin?.data, validationSchema),
    }))
    ?.sort(
      (prev, current) => dayjs(current.created_at) - dayjs(prev.created_at)
    );

  if (fetching) {
    return <LoadingSpinner />;
  }

  const onDelete = (id) => {
    Modal.confirm({
      title: "Delete PIN",
      icon: <ExclamationCircleOutlined />,
      content:
        "You’re going to delete the project idea note, this action cannot be canceled. Do you want to continue?",
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: {
        loading: deleteLoading,
      },
      destroyOnClose: true,
      mask: false,
      onOk: async (close) => {
        await deletePin({
          id,
        });
        close();
      },
    });
  };

  const onDownload = (id) => {
    generatePin({
      id,
    }).then((response) => {
      if (response.data) {
        window.open(response.data.generate_pin?.url, "_blank");
      }
    });
  };

  return (
    <ProjectIdeaNotesTable
      pins={pins}
      onDelete={onDelete}
      onDownload={onDownload}
    />
  );
};

export default ProjectIdeaNotesTableConnected;
