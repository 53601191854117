import React from "react";
import { Modal, Button, Typography } from "antd";
import { Text } from "@antd-ovveride";
import {
  Radio,
  ExclamationCircleOutlined,
} from "@evercityecosystem/evercity-ui";
import styles from "./ObjectiveModal.module.less";
import RadioCard from "../SubstantialContributionPage/RadioCard";
import { Objectives } from "../../entities/Unit/model";

const { Title } = Typography;
const { Group: RadioGroup } = Radio;

const MostRelevantObjectiveModal = ({
  onOk,
  objective,
  availableObjectives,
  onChangeObjective,
  visible,
}) => (
  <Modal
    width={900}
    visible={visible}
    title={
      <div className={styles.headerContent}>
        <ExclamationCircleOutlined />
        <Title level={2} style={{ marginBottom: 0 }}>
          Environmental objectives
        </Title>
      </div>
    }
    wrapClassName={styles.container}
    closable={false}
    footer={
      <Button
        data-cy="btn-next-step"
        onClick={onOk}
        key="next-step"
        type="primary"
        size="large"
      >
        Next step
      </Button>
    }
  >
    <Text className={styles.message} size="large">
      Your economic activity contributes significantly to multiple environmental
      objectives, please indicate the most relevant environmental objective.
    </Text>
    <RadioGroup
      data-cy="radio-objective"
      className={styles.radioGroup}
      onChange={({ id }) => onChangeObjective(id)}
      defaultValue={{ id: objective }}
    >
      <RadioCard
        disabled={
          availableObjectives.indexOf(Objectives.Mitigation.value) === -1
        }
        value={Objectives.Mitigation.value}
        tooltip="Climate change mitigation"
      >
        Mitigation
      </RadioCard>
      <RadioCard
        disabled={
          availableObjectives.indexOf(Objectives.Adaptation.value) === -1
        }
        value={Objectives.Adaptation.value}
        tooltip="Climate change adaptation"
      >
        Adaptation
      </RadioCard>
      <RadioCard
        disabled={availableObjectives.indexOf(Objectives.Water.value) === -1}
        value={Objectives.Water.value}
        tooltip="The sustainable use and protection of water and marine resources"
      >
        Water
      </RadioCard>
      <RadioCard
        disabled={
          availableObjectives.indexOf(Objectives.CircularEconomy.value) === -1
        }
        value={Objectives.CircularEconomy.value}
        tooltip="The transition to a circular economy"
      >
        Circular Economy
      </RadioCard>
      <RadioCard
        disabled={
          availableObjectives.indexOf(Objectives.Pollution.value) === -1
        }
        value={Objectives.Pollution.value}
        tooltip="Pollution prevention and control"
      >
        Pollution
      </RadioCard>
      <RadioCard
        disabled={
          availableObjectives.indexOf(Objectives.Biodiversity.value) === -1
        }
        value={Objectives.Biodiversity.value}
        tooltip="The protection and restoration of biodiversity and ecosystems"
      >
        Biodiversity
      </RadioCard>
    </RadioGroup>
  </Modal>
);

export default MostRelevantObjectiveModal;
