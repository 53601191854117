import React from "react";
import { Space, ContentLayout } from "@evercityecosystem/evercity-ui";
import styles from "./ProjectIdeaNotesPage.module.less";
import ProjectIdeaNotesTableConnected from "../ProjectIdeaNotesTable/ProjectIdeaNotesTableConnected";
import LkLayout from "../LkLayout/LkLayout";
import { AddNewPIN } from "../AddNewPIN";

const ProjectIdeaNotesPage = () => (
  <LkLayout className={styles.layout}>
    <ContentLayout gaps="off">
      <Space justifyContent="end" block className={styles.toolbar}>
        <AddNewPIN />
      </Space>
      <ProjectIdeaNotesTableConnected />
    </ContentLayout>
  </LkLayout>
);
export default ProjectIdeaNotesPage;
