import finParams from "../../data/reportCurrencyParameters";
import validateUnitsByObjectivesAdapter, {
  OBJECTIVES,
  SCREENING_STATUSES,
} from "./validateUnitsByObjectivesAdapter";
import {
  doubleActivitiesAdapter,
  objectivesAdapter,
} from "../../entities/Activity/lib";

const getActReportDnshCreterias = (actReport) => (criteria) => {
  const criteriaToPointSubtype = {
    dnsh_criteria_1: "",
    dnsh_criteria_2: "climate_change",
    dnsh_criteria_3: "sustainable_use",
    dnsh_criteria_4: "circular_economy",
    dnsh_criteria_5: "pollution_prevention",
    dnsh_criteria_6: "protection",
  };
  const { dnshPoints } = actReport.activity;
  const criteriaPoint = dnshPoints.find(
    (p) => p.subtype === criteriaToPointSubtype[criteria]
  );

  if (!criteriaPoint || criteriaPoint.text === "N/A") {
    return "N/A";
  }
  return "Y";
};

const getObjectiveCodes = (
  objectiveCodes,
  mainObjective,
  secondaryObjectives
) => {
  const mainCode = objectiveCodes.find(
    (objetiveCode) => objetiveCode.objective === mainObjective
  )?.text;
  const secondaryCodes = secondaryObjectives.map(
    (objective) =>
      objectiveCodes.find(
        (objetiveCode) => objetiveCode.objective === objective
      )?.text
  );
  const codesSet = new Set([mainCode, ...secondaryCodes]);
  return [...codesSet];
};

const StatusToAlignedMark = {
  [SCREENING_STATUSES.Aligned]: "Y",
  [SCREENING_STATUSES.NotAligned]: "N",
  [SCREENING_STATUSES.NotEligible]: "N/EL",
};

const getAlignedActReportTableData = (report, actReport, finParamName) => {
  const alignedKey = `${finParamName}_alignment`;
  const reportValue = report[finParamName];
  const actReportValue = actReport[finParamName];
  const actReportAlignedValue = actReport[alignedKey];
  const actReportAlignedProportionByTotal = actReportAlignedValue / reportValue;

  const actReportAlignedProportionByActivityTotal =
    actReportAlignedValue / actReportValue;

  const { activity, objective, secondaryObjectives, validationStatuses } =
    actReport;
  const activityType = actReport.activity_type || actReport.activity.type;
  // const isAdaptedAct = activity.adaptation_type === "adapted";
  const codes = getObjectiveCodes(
    activity.objective_codes,
    objective,
    secondaryObjectives
  );
  const getDnshCriteria = getActReportDnshCreterias(actReport);

  // const mitigationAlignedValue = getSumValueByObjective(
  //   actReport.units,
  //   "mitigation",
  //   alignedKey
  // );
  // const mitigationAlignedProportionByTotal =
  //   mitigationAlignedValue / reportValue;

  // const adaptationAlignedValue = getSumValueByObjective(
  //   actReport.units,
  //   "adaptation",
  //   alignedKey
  // );
  // const adaptationAlignedProportionByTotal =
  //   adaptationAlignedValue / reportValue;

  return {
    name: activity.name,
    type: "activity",
    codes,
    objective,
    finValue: actReportAlignedValue,
    finValueProportion: actReportAlignedProportionByTotal,
    sc_criteria_1:
      StatusToAlignedMark[validationStatuses[OBJECTIVES.Mitigation]],
    sc_criteria_2:
      StatusToAlignedMark[validationStatuses[OBJECTIVES.Adaptation]],
    sc_criteria_3: StatusToAlignedMark[validationStatuses[OBJECTIVES.Water]],
    sc_criteria_4:
      StatusToAlignedMark[validationStatuses[OBJECTIVES.Pollution]],
    sc_criteria_5:
      StatusToAlignedMark[validationStatuses[OBJECTIVES.CircularEconomy]],
    sc_criteria_6:
      StatusToAlignedMark[validationStatuses[OBJECTIVES.Biodiversity]],
    dnsh_criteria_1: getDnshCriteria("dnsh_criteria_1"),
    dnsh_criteria_2: getDnshCriteria("dnsh_criteria_2"),
    dnsh_criteria_3: getDnshCriteria("dnsh_criteria_3"),
    dnsh_criteria_4: getDnshCriteria("dnsh_criteria_4"),
    dnsh_criteria_5: getDnshCriteria("dnsh_criteria_5"),
    dnsh_criteria_6: getDnshCriteria("dnsh_criteria_6"),
    mss: "Y",
    aligned_fin_value: actReportAlignedProportionByActivityTotal,
    aligned_fin_value_prev_year: "",
    category_enabling: activityType === "enabling" ? "E" : "",
    category_transitional: activityType === "transitional" ? "T" : "",
  };
};

const StatusToNotAlignedMark = {
  [SCREENING_STATUSES.Aligned]: "EL",
  [SCREENING_STATUSES.NotAligned]: "EL",
  [SCREENING_STATUSES.NotEligible]: "N/EL",
};

const getNotAlignedActReportTableData = (report, actReport, finParamName) => {
  const alignedKey = `${finParamName}_alignment`;
  const reportValue = report[finParamName];
  const actReportValue = actReport[finParamName];
  const actReportAlignedValue = actReport[alignedKey];

  const actReportNotAlignedValue = actReportValue - actReportAlignedValue;
  const actReportNotAlignedProportionByTotal =
    actReportNotAlignedValue / reportValue;

  const { activity, objective, secondaryObjectives, validationStatuses } =
    actReport;
  const codes = getObjectiveCodes(
    activity.objective_codes,
    objective,
    secondaryObjectives
  );
  const activityType = actReport.activity_type || actReport.activity.type;

  return {
    name: activity.name,
    type: "activity",
    codes,
    objective,
    finValue: actReportNotAlignedValue,
    finValueProportion: actReportNotAlignedProportionByTotal,
    category_enabling: activityType === "enabling" ? "E" : "",
    category_transitional: activityType === "transitional" ? "T" : "",
    sc_criteria_1:
      StatusToNotAlignedMark[validationStatuses[OBJECTIVES.Mitigation]],
    sc_criteria_2:
      StatusToNotAlignedMark[validationStatuses[OBJECTIVES.Adaptation]],
    sc_criteria_3: StatusToNotAlignedMark[validationStatuses[OBJECTIVES.Water]],
    sc_criteria_4:
      StatusToNotAlignedMark[validationStatuses[OBJECTIVES.Pollution]],
    sc_criteria_5:
      StatusToNotAlignedMark[validationStatuses[OBJECTIVES.CircularEconomy]],
    sc_criteria_6:
      StatusToNotAlignedMark[validationStatuses[OBJECTIVES.Biodiversity]],
  };
};

const getTableRows = (report, actReports, finParamName) => {
  const alignedKey = `${finParamName}_alignment`;
  const eligibleKey = `${finParamName}_eligibility`;
  const reportTotalValue = report[finParamName];

  const reportEligibleValue = report[eligibleKey];
  const reportEligibleProportion = reportEligibleValue / reportTotalValue;

  const reportNotEligibleValue = reportTotalValue - reportEligibleValue;
  const reportNotEligibleProportion = 1 - reportEligibleProportion;

  const reportAlignedValue = report[alignedKey];
  const reportAlignedPercent = reportAlignedValue / reportTotalValue;
  const alignedFinValue = reportAlignedValue / reportEligibleValue;
  const reportNotAlignedValue = reportEligibleValue - reportAlignedValue;
  const reportNotAlignedProportion = reportNotAlignedValue / reportTotalValue;

  const eligibleActReports = doubleActivitiesAdapter(
    objectivesAdapter(actReports)
  ).filter((ar) => ar[finParamName] > 0);

  const filteredAlignmentActs = eligibleActReports
    .filter((ar) => ar[alignedKey] > 0)
    .map((actReport) => ({
      ...actReport,
      units: actReport.units.filter((unit) => unit[alignedKey] > 0),
    }));
  const alignedActReports = validateUnitsByObjectivesAdapter(
    filteredAlignmentActs,
    finParamName
  );

  const alignedActReportsTableRows = alignedActReports.map((actReport) =>
    getAlignedActReportTableData(report, actReport, finParamName)
  );

  // по turnover: если у активности turnover_alignment не равен ее turnover
  // значит у нее есть not-aligned часть и ее нужно вывести
  const filteredNotAlignmentActs = eligibleActReports
    .filter((actReport) => actReport[alignedKey] < actReport[finParamName])
    .map((actReport) => ({
      ...actReport,
      units: actReport.units.filter((unit) => unit[alignedKey] === 0),
    }));
  const notAlignedActReports = validateUnitsByObjectivesAdapter(
    filteredNotAlignmentActs,
    finParamName
  );

  const notAlignedActReportsTableRows = notAlignedActReports.map((actReport) =>
    getNotAlignedActReportTableData(report, actReport, finParamName)
  );

  const reportEnablingValue = alignedActReports.reduce((sum, activity) => {
    if (activity.activity_type === "enabling") {
      return sum + activity[alignedKey];
    }
    return sum;
  }, 0);

  const reportTransitionalValue = alignedActReports.reduce((sum, activity) => {
    if (activity.activity_type === "transitional") {
      return sum + activity[alignedKey];
    }
    return sum;
  }, 0);

  const getAlignedProportionByObjective = (objective) => {
    if (reportAlignedValue === 0) {
      return 0;
    }
    const valuePerObjective = alignedActReports.reduce((result, actReport) => {
      if (
        actReport.validationStatuses[objective] === SCREENING_STATUSES.Aligned
      ) {
        return result + actReport[alignedKey];
      }
      return result;
    }, 0);

    return valuePerObjective / reportAlignedValue;
  };

  const getNotAlignedProportionByObjective = (objective) => {
    if (reportNotAlignedValue === 0) {
      return 0;
    }
    const valuePerObjective = notAlignedActReports.reduce(
      (result, actReport) => {
        if (
          actReport.validationStatuses[objective] ===
            SCREENING_STATUSES.NotAligned ||
          actReport.validationStatuses[objective] === SCREENING_STATUSES.Aligned
        ) {
          return result + (actReport[finParamName] - actReport[alignedKey]);
        }
        return result;
      },
      0
    );

    return valuePerObjective / reportNotAlignedValue;
  };

  const rows = [
    // ELIGIBLE
    {
      name: "A. TAXONOMY-ELIGIBLE ACTIVITIES",
      type: "category",
    },
    // ALIGNED
    {
      name: "A.1. Environmentally sustainable activities (Taxonomy-aligned)",
      type: "subcategory",
    },
    ...alignedActReportsTableRows,
    // ALIGNED - SUM
    {
      name: `${finParams[finParamName]} of environmentally sustainable activities (Taxonomy-aligned) (A.1.)`,
      type: "sum",
      codes: "",
      finValue: reportAlignedValue,
      finValueProportion: reportAlignedPercent,
      sc_criteria_1: getAlignedProportionByObjective(OBJECTIVES.Mitigation),
      sc_criteria_2: getAlignedProportionByObjective(OBJECTIVES.Adaptation),
      sc_criteria_3: getAlignedProportionByObjective(OBJECTIVES.Water),
      sc_criteria_4: getAlignedProportionByObjective(OBJECTIVES.Pollution),
      sc_criteria_5: getAlignedProportionByObjective(
        OBJECTIVES.CircularEconomy
      ),
      sc_criteria_6: getAlignedProportionByObjective(OBJECTIVES.Biodiversity),
      aligned_fin_value: alignedFinValue,
      aligned_fin_value_prev_year: "",
    },
    {
      name: "Of which Enabling",
      type: "sum",
      finValue: reportEnablingValue,
      finValueProportion: reportEnablingValue / reportTotalValue,
      sc_criteria_1:
        getAlignedProportionByObjective(OBJECTIVES.Mitigation) *
        (reportEnablingValue / reportTotalValue),
      sc_criteria_2:
        getAlignedProportionByObjective(OBJECTIVES.Adaptation) *
        (reportEnablingValue / reportTotalValue),
      sc_criteria_3:
        getAlignedProportionByObjective(OBJECTIVES.Water) *
        (reportEnablingValue / reportTotalValue),
      sc_criteria_4:
        getAlignedProportionByObjective(OBJECTIVES.Pollution) *
        (reportEnablingValue / reportTotalValue),
      sc_criteria_5:
        getAlignedProportionByObjective(OBJECTIVES.CircularEconomy) *
        (reportEnablingValue / reportTotalValue),
      sc_criteria_6:
        getAlignedProportionByObjective(OBJECTIVES.Biodiversity) *
        (reportEnablingValue / reportTotalValue),
    },
    {
      name: "Of which Transitional",
      type: "sum",
      finValue: reportTransitionalValue,
      finValueProportion: reportTransitionalValue / reportTotalValue,
      sc_criteria_1:
        getAlignedProportionByObjective(OBJECTIVES.Mitigation) *
        (reportTransitionalValue / reportTotalValue),
    },
    // NOT-ALIGNED
    {
      name: "A.2 Taxonomy-Eligible but not environmentally sustainable activities (not Taxonomy-aligned activities)",
      type: "subcategory",
    },
    ...notAlignedActReportsTableRows,
    // NOT ALIGNED - SUM
    {
      name: `${finParams[finParamName]} of Taxonomy-eligible but not environmentally sustainable activities (not Taxonomy-aligned activities)`,
      type: "sum",
      codes: "",
      finValue: reportNotAlignedValue,
      finValueProportion: reportNotAlignedProportion,
      sc_criteria_1: getNotAlignedProportionByObjective(OBJECTIVES.Mitigation),
      sc_criteria_2: getNotAlignedProportionByObjective(OBJECTIVES.Adaptation),
      sc_criteria_3: getNotAlignedProportionByObjective(OBJECTIVES.Water),
      sc_criteria_4: getNotAlignedProportionByObjective(OBJECTIVES.Pollution),
      sc_criteria_5: getNotAlignedProportionByObjective(
        OBJECTIVES.CircularEconomy
      ),
      sc_criteria_6: getNotAlignedProportionByObjective(
        OBJECTIVES.Biodiversity
      ),
    },
    // ELIGIBLE - SUM
    {
      name: "Total (A.1 + A.2)",
      type: "sum",
      codes: "",
      finValue: reportEligibleValue,
      finValueProportion: reportEligibleProportion,
      aligned_fin_value: alignedFinValue,
    },
    {
      name: "B. TAXONOMY-NON-ELIGIBLE ACTIVITIES",
      type: "category",
    },
    {
      name: `${finParams[finParamName]} of Taxonomy-non-eligible activities (B)`,
      type: "sum",
      codes: "",
      finValue: reportNotEligibleValue,
      finValueProportion: reportNotEligibleProportion,
    },
    {
      name: "Total (A + B)",
      type: "sum",
      codes: "",
      finValue: reportTotalValue,
      finValueProportion: 1,
    },
  ];
  return rows.map((row, index) => ({
    ...row,
    key: index,
  }));
};

export default getTableRows;
