import React from "react";
import {
  Avatar,
  BellOutlined,
  Layout,
  Badge,
  Text,
  Button,
  DownOutlined,
  ExternalLink,
} from "@evercityecosystem/evercity-ui";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { useMatches, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/user";
import useSubscribeOnNotifications from "../../hooks/useSubscribeOnNotifications";

const AUTH_URL = process.env.REACT_APP_AUTH_URL;

const AppHeader = ({ tag, title, goBack, content }) => {
  const matches = useMatches();

  const navigate = useNavigate();
  const [userState, { logout }] = useCurrentUser();

  const { notificationsCount, onReadNotifications } =
    useSubscribeOnNotifications();

  const userIsAuth = !userState.error && !!userState?.data?.id;

  const routes = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => ({
      breadcrumbName: match.handle.crumb(match.data),
      path: match.pathname,
    }));
  return (
    <Layout.Header
      tag={tag}
      title={title}
      onBack={
        goBack &&
        (() => {
          navigate(-1);
        })
      }
      breadcrumb={{ routes }}
      actions={
        userIsAuth ? (
          <>
            <Button
              type="text"
              icon={
                <Badge count={notificationsCount} size="small">
                  <BellOutlined />
                </Badge>
              }
              onClick={() => onReadNotifications()}
            />
            <ExternalLink href={process.env.REACT_APP_AUTH_URL}>
              <Avatar size="small" name={userState?.data?.display_name} />
            </ExternalLink>
            <Text type="secondary">
              EN <DownOutlined />
            </Text>
            <Button icon={<LogoutOutlined />} type="primary" onClick={logout}>
              Logout
            </Button>
          </>
        ) : (
          <Button
            data-cy="login"
            icon={<LoginOutlined />}
            type="primary"
            onClick={() => {
              window.location.replace(`${AUTH_URL}/signin`);
            }}
          >
            Login
          </Button>
        )
      }
    >
      {content}
    </Layout.Header>
  );
};

export default AppHeader;
