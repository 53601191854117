import React, { useMemo } from "react";
import reportFinParams from "../../data/reportCurrencyParameters";
import ReportPdfTable from "../ReportPdfTable/ReportPdfTable";
import { Space } from "../../antd-ovveride";
import ReportPdfObjectives from "../ReportPdfTable/ReportPdfObjectives";

const ReportPdf = ({ actReports, report, pagination }) => {
  const content = useMemo(() => {
    if ([1, 2, 3].includes(pagination)) {
      return (
        <ReportPdfTable
          finParamName={Object.keys(reportFinParams)[pagination - 1]}
          actReports={actReports}
          report={report}
          company={report.company}
          paginationIndex={pagination}
        />
      );
    }

    if (pagination === 4) {
      return <ReportPdfObjectives actReports={actReports} report={report} />;
    }
    return null;
  }, [pagination, report, actReports]);

  return (
    <Space size={30} direction="vertical">
      <div key={pagination} style={{ display: "inline-flex" }}>
        {content}
      </div>
    </Space>
  );
};

export default ReportPdf;
