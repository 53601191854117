import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/user";

export const HomeRedirect = () => {
  const [userState] = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    const config = userState?.company?.settings;
    const hasAccessToPIN = Boolean(config.PIN);

    if (hasAccessToPIN) {
      navigate("/company/pins");
    } else {
      navigate("/company/taxonomy");
    }
  }, [userState, navigate]);

  return null;
};
