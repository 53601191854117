import React, { useMemo } from "react";
import { node } from "prop-types";
import {
  Layout,
  BookOutlined,
  ExternalLink,
} from "@evercityecosystem/evercity-ui";
import { useCurrentUser } from "../../hooks/user";
import ScrollToThisOnRedirect from "./ScrollToThisOnRedirect";
import { ReactComponent as ListIcon } from "../../assets/sidebar/list.svg";
import { ReactComponent as CardIcon } from "../../assets/sidebar/card.svg";
import { ReactComponent as FileIcon } from "../../assets/sidebar/file.svg";
import { ReactComponent as FolderIcon } from "../../assets/sidebar/folder.svg";
import { ReactComponent as MapIcon } from "../../assets/sidebar/map.svg";
import AppHeader from "./AppHeader";

const ITEMS = [
  {
    name: "Listing",
    icon: <ListIcon />,
    to: `https://carbon${process.env.REACT_APP_SESSION_DOMAIN}`,
  },
  {
    name: "Portfolio",
    icon: <CardIcon />,
    to: `https://carbon${process.env.REACT_APP_SESSION_DOMAIN}/portfolio`,
    checkDisabled: ({ hasAccessToPIN }) => hasAccessToPIN,
  },
  {
    name: "EU Taxonomy",
    icon: <FileIcon />,
    checkDisabled: ({ companyType, constraints, hasAccessToPIN }) =>
      companyType === "investor" ||
      constraints.includes("Taxonomy") ||
      hasAccessToPIN,
    to: "/company/taxonomy",
  },
  {
    name: "Frameworks",
    icon: <FolderIcon />,
    checkDisabled: ({ companyType, constraints, hasAccessToPIN }) =>
      companyType === "investor" ||
      constraints.includes("Framework") ||
      hasAccessToPIN,
    to: "/company/frameworks",
  },
  {
    name: "ESG map",
    icon: <MapIcon />,
    to: "/map",
  },
  {
    name: "PIN",
    icon: <BookOutlined />,
    to: "/company/pins",
    checkDisabled: ({ hasAccessToPIN }) => !hasAccessToPIN,
  },
];

const LkLayout = ({ children, header, className }) => {
  const [userState] = useCurrentUser();
  const userIsAuth = !userState.error && !!userState?.data?.id;

  const menu = useMemo(() => {
    const companyType = userState?.company?.type;
    const config = userState?.company?.settings;
    const hasAccessToPIN = Boolean(config.PIN);
    const constraints = config?.restrictedServices || [];

    return ITEMS.map((item) => ({
      ...item,
      disabled:
        item.disabled ||
        item.checkDisabled?.({
          hasAccessToPIN,
          companyType,
          constraints,
        }),
    })).filter((item) => !item.disabled);
  }, [userState]);

  return (
    <Layout variant={userIsAuth ? "personal" : "public"}>
      {userIsAuth && <Layout.Sider items={menu} />}
      <AppHeader {...header} />
      <Layout variant="internal" className={className}>
        <ScrollToThisOnRedirect />
        <Layout.Content>{children}</Layout.Content>
        <Layout.Footer>
          <ExternalLink href="">Cookies Policy</ExternalLink>
          <ExternalLink href="https://storage.googleapis.com/evercity-public/Privacy%20Policy.pdf">
            Privacy Policy
          </ExternalLink>
          <ExternalLink href="https://storage.googleapis.com/evercity-public/Terms%20of%20service.pdf">
            Terms of Use
          </ExternalLink>
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};

LkLayout.propTypes = {
  children: node.isRequired,
};

export default LkLayout;
