import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { NavLink, Link } from "react-router-dom";
import { UIProvider } from "@evercityecosystem/evercity-ui";
import { Provider } from "urql";
import SuperTokens from "supertokens-web-js/lib/build/supertokens";
import Session from "supertokens-web-js/recipe/session";
import { SignJWT } from "jose";
import CountryProvider from "./components/CountryProvider/CountryProvider";
import Error500 from "./components/errors/Error500";
import reportWebVitals from "./reportWebVitals";
import "./helpers/sentry";

import "@evercityecosystem/evercity-ui/dist/style.css";
import "./styles/index.less";
import App from "./components/App";
import graphqlClient from "./graphqlClient";
import { LS_TOKEN_KEY } from "./helpers/jwt";
import { initSessionFx } from "./entities/User/model";
import { BACKEND_ENV, PDF_MODE } from "./shared/lib/constants";

const uiconfig = {
  components: {
    NavLink,
    Link,
  },
};

const initApp = async () => {
  if (PDF_MODE) {
    return;
  }

  if (BACKEND_ENV === "same-origin") {
    SuperTokens.init({
      appInfo: {
        appName: "legacy",
        apiDomain:
          process.env.REACT_APP_AUTH_API_URL || "https://localhost:4000",
      },
      recipeList: [
        Session.init({
          sessionTokenFrontendDomain: process.env.REACT_APP_SESSION_DOMAIN,
        }),
      ],
    });
  }

  if (
    process.env.NODE_ENV === "development" &&
    BACKEND_ENV === "local" &&
    !localStorage.getItem(LS_TOKEN_KEY)
  ) {
    const secret = new TextEncoder().encode(process.env.REACT_APP_JWT_SECRET);

    const jwtToken = await new SignJWT({
      [process.env.REACT_APP_JWT_CLAIMS_NAMESPACE]: {
        "x-hasura-user-id": process.env.REACT_APP_STATIC_CURRENT_USER_ID,
        "x-hasura-allowed-roles":
          process.env.REACT_APP_ALLOWED_ROLES.split(","),
        "x-hasura-default-role": "user",
      },
    })
      .setProtectedHeader({
        alg: process.env.REACT_APP_JWT_ALGORITHM,
      })
      .setIssuedAt()
      .sign(secret);

    localStorage.setItem(LS_TOKEN_KEY, jwtToken);
    window.location.reload();
  }
};

initApp().then(() => {
  initSessionFx();

  ReactDOM.render(
    <React.StrictMode>
      <UIProvider config={uiconfig}>
        <Sentry.ErrorBoundary fallback={<Error500 />}>
          <CountryProvider>
            <Provider value={graphqlClient}>
              <App />
            </Provider>
          </CountryProvider>
        </Sentry.ErrorBoundary>
      </UIProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
  reportWebVitals();
});
