import React from "react";
import { Table } from "antd";
import cs from "classnames";

import { ReactComponent as HorizontalBraceIcon } from "../../assets/icons/horizontal-brace.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";

import reportFinParams from "../../data/reportCurrencyParameters";
import styles from "./ReportPdfTable.module.less";

import textTransformations from "./textTransformations";
import HeaderCell from "./HeaderCell";
import getTableRows from "./getTableRows";
import { OBJECTIVES } from "./validateUnitsByObjectivesAdapter";

const rowWithSingleValue = (row) =>
  ["category", "subcategory"].includes(row.type);

const renderNameCell = (text, row) => {
  const isFullWidthCell = rowWithSingleValue(row);
  const classNameByType =
    {
      category: "categroyCell",
      subcategory: "subcategoryCell",
      activity: "itemNameCell",
      sum: "subcategoryCell",
    }[row.type] || "itemNameCell";

  return {
    children: (
      <div
        className={cs(
          styles[classNameByType],
          isFullWidthCell && styles.fullWidthCell
        )}
      >
        {text}
      </div>
    ),
    props: isFullWidthCell
      ? {
          colSpan: 1000,
        }
      : {},
  };
};

const renderValueCell =
  (textTransformMethod = textTransformations.default, modificator) =>
  (text) =>
    (
      <div
        className={cs(styles.valueCell, styles[`valueCell--${modificator}`])}
      >
        {textTransformMethod(text)}
      </div>
    );

const primaryObjectiveDecorator =
  (func, targetObjective) => (value, record, index) => {
    const { children, ...result } = func(value, record, index);
    const { objective } = record;
    return {
      ...result,
      children: (
        <div
          className={cs({
            [styles["valueCell--bold"]]: targetObjective === objective,
          })}
        >
          {children}
        </div>
      ),
    };
  };

const renderContent = (contentType) => (text, row, index) => {
  const contentTypeToRenderMethods = {
    codes: renderValueCell(textTransformations.codes, "small"),
    finValue: renderValueCell(textTransformations.currency_mln),
    finValueProportion: renderValueCell(textTransformations.percent),
    sc_criteria: renderValueCell(textTransformations.percentOrText, "theme-1"),
    dnsh_criteria: renderValueCell(textTransformations.default, "theme-2"),
    bool: renderValueCell(textTransformations.bool),
    proportion: renderValueCell(textTransformations.percent),
    text: renderValueCell(),
  };

  const cellRenderMethod =
    contentTypeToRenderMethods[contentType] || contentTypeToRenderMethods.text;

  const content = cellRenderMethod(text, row, index);
  let props = {};
  if (rowWithSingleValue(row)) {
    props = {
      colSpan: 0,
    };
  }
  return {
    children: content,
    props,
  };
};

const getColumns = (finParamDisplayName, currency) => {
  const columns = [
    {
      title: "",
      children: [
        {
          title: () => (
            <span className={styles.headerMainCell}>Economic activities</span>
          ),
          dataIndex: "name",
          key: "name",
          render: renderNameCell,
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => <HeaderCell>Code&nbsp;({index})</HeaderCell>,
          dataIndex: "codes",
          key: "codes",
          render: renderContent("codes"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure={`${currency}, mln`}>
              Absolute {finParamDisplayName}&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "finValue",
          key: "finValue",
          render: renderContent("finValue"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure="%">
              Proportion of {finParamDisplayName}&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "finValueProportion",
          key: "finValueProportion",
          render: renderContent("finValueProportion"),
        },
      ],
    },
    {
      title: (
        <div className={styles.headerCellWithBrace}>
          Substantial contribution criteria
          <HorizontalBraceIcon />
        </div>
      ),
      children: [
        {
          title: (index) => (
            <HeaderCell measure="Y;N;N/EL" theme="1">
              Climate change mitigation&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "sc_criteria_1",
          key: "sc_criteria_1",
          render: primaryObjectiveDecorator(
            renderContent("sc_criteria"),
            OBJECTIVES.Mitigation
          ),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y;N;N/EL" theme="1">
              Climate change adaptation&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "sc_criteria_2",
          key: "sc_criteria_2",
          render: primaryObjectiveDecorator(
            renderContent("sc_criteria"),
            OBJECTIVES.Adaptation
          ),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y;N;N/EL" theme="1">
              Water and marine resourses&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "sc_criteria_3",
          key: "sc_criteria_3",
          render: primaryObjectiveDecorator(
            renderContent("sc_criteria"),
            OBJECTIVES.Water
          ),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y;N;N/EL" theme="1">
              Circular economy&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "sc_criteria_4",
          key: "sc_criteria_4",
          render: primaryObjectiveDecorator(
            renderContent("sc_criteria"),
            OBJECTIVES.CircularEconomy
          ),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y;N;N/EL" theme="1">
              Pollution&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "sc_criteria_5",
          key: "sc_criteria_5",
          render: primaryObjectiveDecorator(
            renderContent("sc_criteria"),
            OBJECTIVES.Pollution
          ),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y;N;N/EL" theme="1">
              Biodiversity and ecosystems&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "sc_criteria_6",
          key: "sc_criteria_6",
          render: primaryObjectiveDecorator(
            renderContent("sc_criteria"),
            OBJECTIVES.Biodiversity
          ),
        },
      ],
    },
    {
      title: (
        <div className={styles.headerCellWithBrace}>
          DNSH criteria (‘Does Not Significantly Harm’)
          <HorizontalBraceIcon />
        </div>
      ),
      children: [
        {
          title: (index) => (
            <HeaderCell measure="Y/N" theme="2">
              Climate change mitigation&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "dnsh_criteria_1",
          key: "dnsh_criteria_1",
          render: renderContent("dnsh_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y/N" theme="2">
              Climate change adaptation&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "dnsh_criteria_2",
          key: "dnsh_criteria_2",
          render: renderContent("dnsh_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y/N" theme="2">
              Water and marine resourses&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "dnsh_criteria_3",
          key: "dnsh_criteria_3",
          render: renderContent("dnsh_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y/N" theme="2">
              Circular economy&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "dnsh_criteria_4",
          key: "dnsh_criteria_4",
          render: renderContent("dnsh_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y/N" theme="2">
              Pollution&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "dnsh_criteria_5",
          key: "dnsh_criteria_5",
          render: renderContent("dnsh_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y/N" theme="2">
              Biodiversity and ecosystems&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "dnsh_criteria_6",
          key: "dnsh_criteria_6",
          render: renderContent("dnsh_criteria"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure="Y/N">
              Minimum saveguards&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "mss",
          key: "mss",
          render: renderContent("text"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure="%" horizontal>
              Taxonomy-aligned proportion of {finParamDisplayName}, year
              N-1&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "aligned_fin_value_prev_year",
          key: "aligned_fin_value_prev_year",
          render: renderContent("proportion"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure="E" horizontal>
              Category (enabling activity)&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "category_enabling",
          key: "category_enabling",
          render: renderContent("text"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure="T" horizontal>
              Category (transitional activity)&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "category_transitional",
          key: "category_transitional",
          render: renderContent("text"),
        },
      ],
    },
  ];
  let counter = 0;
  return columns.map((col) => ({
    ...col,
    children: col.children.map((child) => {
      counter += 1;
      return {
        ...child,
        title: child.title(counter),
      };
    }),
  }));
};

const ReportPdfTable = ({
  finParamName,
  paginationIndex,
  actReports,
  report,
  company,
}) => {
  const finParamDisplayName = reportFinParams[finParamName];
  const columns = getColumns(finParamDisplayName, report.currency);
  const tableRows = getTableRows(report, actReports, finParamName);
  return (
    <div className={styles.page}>
      <p className={styles.pagination}>{paginationIndex}/4</p>
      <div className={styles.pageTitleWrapper}>
        <h1 className={styles.pageTitle}>Taxonomy Report</h1>
        <div className={styles.logo}>
          <LogoIcon />
        </div>
      </div>

      <p className={styles.description}>
        Proportion of {finParamDisplayName} from products or services associated
        with Taxonomy-aligned economic activities
      </p>
      <div className={styles.twoColsList}>
        <div className={styles.companyInfoKey}>Company</div>
        <div className={styles.companyInfoValue}>{company.name}</div>
        <div className={styles.companyInfoKey}>Year (N)</div>
        <div className={styles.companyInfoValue}>{report.reporting_year}</div>
      </div>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={tableRows}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default ReportPdfTable;
