import React, { useState } from "react";
import { Badge, Button, Space, Typography } from "antd";
import {
  LogoutOutlined,
  QuestionCircleOutlined,
  LoginOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useLocation, Link, useNavigate } from "react-router-dom";
import cn from "classnames";

import styles from "./AppHeader.module.less";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { useLogout } from "../../hooks/auth";
import EntryModal from "../EntryModal/EntryModal";
import { useCurrentUser } from "../../hooks/user";

const AppHeader = () => {
  const logout = useLogout();
  const [entryModalVisible, setEntryModalVisible] = useState(false);
  const location = useLocation();
  const [userState] = useCurrentUser();
  const navigate = useNavigate();

  // const hideOnPages = !["/"].includes(location.pathname);
  const hideOnDemoPage = location.pathname !== "/request-demo";
  const { fetching } = userState;
  const isUser = !!userState.data?.id;

  return (
    <>
      <EntryModal
        visible={entryModalVisible}
        startTime={0}
        closable
        autoplay
        onHide={() => setEntryModalVisible(false)}
      />

      <div className={styles.container}>
        <div
          className={cn(styles.logoWrapper, {
            [styles[`logoWrapper--disabled`]]: location.pathname === "/",
          })}
          onClick={() => navigate("/")}
          onKeyDown={() => navigate("/")}
          role="button"
          tabIndex="-1"
        >
          <LogoIcon />
        </div>

        <div className={styles.actionsWrapper}>
          {!fetching && isUser ? (
            <>
              <Link to="/company/team" className={styles.team__link}>
                <TeamOutlined className={styles.team__icon} />
              </Link>
              <LogoutOutlined
                className={styles.bellIcon}
                onClick={() => logout()}
              />
              <Badge
                count={0}
                size="small"
                tabIndex={0}
                onKeyDown={() => {
                  setEntryModalVisible(true);
                }}
                onClick={() => setEntryModalVisible(true)}
                className={styles.help}
              >
                <Space>
                  <QuestionCircleOutlined className={styles.bellIcon} />
                  <Typography.Text className={styles.loginLabel}>
                    Need help
                  </Typography.Text>
                </Space>
              </Badge>
            </>
          ) : (
            <>
              <Link data-cy="login" className={styles.login} to="/login">
                <Space size={10}>
                  <LoginOutlined className={styles.loginIcon} />
                  <Typography.Text className={styles.loginLabel}>
                    Login
                  </Typography.Text>
                </Space>
              </Link>

              {hideOnDemoPage && (
                <Link to="/request-demo">
                  <Button size="small" className={styles.getDemo}>
                    Get a demo
                  </Button>
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AppHeader;
