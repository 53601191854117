export const Objectives = {
  Mitigation: {
    priority: 1,
    value: "mitigation",
  },
  Adaptation: {
    priority: 2,
    value: "adaptation",
  },
  Water: {
    value: "water",
    priority: 3,
  },
  CircularEconomy: {
    priority: 4,
    value: "circularEconomy",
  },
  Pollution: {
    value: "pollution",
    priority: 5,
  },
  Biodiversity: {
    value: "biodiversity",
    priority: 6,
  },
};
