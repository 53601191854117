import React, { useMemo } from "react";
import { Space } from "@antd-ovveride";
import { Tooltip } from "antd";
import makeChunks from "lodash.chunk";
import CodeTag from "./CodeTag";
import { ReactComponent as MoreOutlinedHorizontal } from "../../assets/more-outlined-horizontal.svg";

const MoreTag = ({ codes }) => (
  <Tooltip title={codes} destroyTooltipOnHide>
    <div>
      <CodeTag text={<MoreOutlinedHorizontal />} isSelected disabled />
    </div>
  </Tooltip>
);

const CodesList = ({ codes, maxCount = 3, maxCountInRow = 0 }) => {
  const [codesInTags, codesInTooltip] = useMemo(
    () => [
      codes.slice(0, maxCount),
      codes
        .slice(maxCount)
        .map((c) => c.code)
        .join(", "),
    ],
    [codes, maxCount]
  );

  const chunks = useMemo(
    () =>
      maxCountInRow
        ? makeChunks(codesInTags, Math.min(maxCountInRow, codesInTags.length))
        : [codesInTags],
    [codesInTags, maxCountInRow]
  );

  const lastChunkFull = useMemo(
    () => maxCountInRow && chunks[chunks.length - 1].length === maxCountInRow,
    [chunks, maxCountInRow]
  );

  return (
    <Space
      block
      direction="vertical"
      size={3}
      style={{ fontSize: 0, poinerEvents: "none" }}
    >
      {chunks.map((chunk, chunkIndex) => (
        <Space size={3} key={chunkIndex.toString()}>
          {chunk.map((code, codeIndex) => (
            <React.Fragment key={code.code}>
              <CodeTag text={code.code} isSelected disabled />
              {!lastChunkFull &&
                codeIndex === chunk.length - 1 &&
                chunkIndex === chunks.length - 1 &&
                !!codesInTooltip.length && <MoreTag codes={codesInTooltip} />}
            </React.Fragment>
          ))}
        </Space>
      ))}
      {!!lastChunkFull && !!codesInTooltip.length && (
        <MoreTag codes={codesInTooltip} />
      )}
    </Space>
  );
};

export default CodesList;
